import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function ChildrenProductKupanie() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const type = filtersAll.filter((item) => item.type === 55)

  return (
    <PageWrapper
      isLinks={true}
      categoryRoute={null}
      columnRoute={'detskie'}
      subCategoryRoute={'kupanie'}
      category={category}
      categoryId={1}
      columnsId={4}
      subCategoryId={55}
      title={'Купание'}
      subTitle={'Детские товары'}
      isAksessuary={true}
      aksessuary={type}
    />
  )
}
export default ChildrenProductKupanie
